import Image from 'next/future/image';
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import { BABY_CATEGORIES } from '@lambda/reebelo/src/commonHelpers';
import i18n from '@/settings/i18n';
import conditionDescriptionMap from '@/settings/condition-description';
import Slider from '../../slider/Slider';
import { Breakpoints } from '@/settings/breakpoints';
import premiumBg from '@/public/icons/product/premium_bg.svg';
import conditionImagesMap from '@/settings/condition-image';
import { isNaStore } from '@/lib/collections/catalogue/helpers';
import ConditionTab from './ConditionTab';

const t = i18n.init();

export const CONDITIONS = [
  {
    title: t`Acceptable`,
    handle: 'acceptable',
    desc: t`Visible scratches and possible dents. All devices sold are 100% functional, tested and cleaned.`,
  },
  {
    title: t`Good`,
    handle: 'good',
    desc: t`Light scratches, visible from 20cm away. All devices sold are 100% functional, tested and cleaned`,
  },
  {
    title: t`Excellent`,
    handle: 'excellent',
    desc: t`Light scratches, invisible from 20cm away. All devices sold are 100% functional, tested and cleaned.`,
  },
  {
    title: t`Premium`,
    handle: 'premium',
    desc: t`No scratches, no dents. Brand new look! All devices sold are 100% functional, tested and cleaned.`,
  },
  {
    title: t`Brand New`,
    handle: 'brand-new',
    desc: t`Comes in its original sealed box complete with accessories and free vendor warranty. Exactly what it is - New!`,
  },
];
const BABY_CONDITIONS = [
  {
    title: t`Acceptable`,
    handle: 'acceptable',
    desc: t`Prominent scratches and signs of wear visible upon close inspection. They do not impact functionality.`,
  },
  {
    title: t`Good`,
    handle: 'good',
    desc: t`Minor but visible scratches & signs of wear.`,
  },
  {
    title: t`Excellent`,
    handle: 'excellent',
    desc: t`Minimal scratches & signs of wear that are barely noticeable.`,
  },
  {
    title: t`Premium`,
    handle: 'premium',
    desc: t`No signs of wear with a like-new look & feel.`,
  },
  {
    title: t`Over Stock`,
    handle: 'over-stock',
    desc: t`No signs of wear. Brand new product and packaging from supplier due to excess stock.`,
  },
];

const breakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 1.1,
    spaceBetween: 12,
  },
  [Breakpoints.XXS]: {
    slidesPerView: 1.1,
    spaceBetween: 20,
  },
  [Breakpoints.XS]: {
    slidesPerView: 1.2,
    spaceBetween: 30,
  },
  [Breakpoints.MD]: {
    slidesPerView: 2.5,
    spaceBetween: 30,
  },
  [Breakpoints.LG]: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  [Breakpoints.XL]: {
    slidesPerView: 1.5,
    spaceBetween: 20,
  },
};

const ConditionPopup = React.memo(
  (props: {
    isOpen: boolean;
    activeCondition: string;
    onClose: () => void;
    category: string;
    isOldPdp?: boolean;
  }) => {
    const { activeCondition, isOpen, isOldPdp = false } = props;
    const { category } = props;

    let NEW_CONDITIONS = BABY_CATEGORIES.includes(category)
      ? BABY_CONDITIONS
      : CONDITIONS;

    // exclude Brand New in US and CA
    if (isNaStore && !isOldPdp)
      NEW_CONDITIONS = CONDITIONS.filter((c) => c.handle !== 'brand-new');

    const hasPremiumBattery = [
      'Smartphones',
      'Laptops',
      'Tablets',
      'Smartwatches',
    ].includes(category);

    const [currentActiveCondition, setSetCurrentActiveCondition] =
      useState(activeCondition);

    const activeIndex = NEW_CONDITIONS.map((object) => object.title).indexOf(
      currentActiveCondition,
    );
    const [selectedIndex, setSelectedIndex] = useState(activeIndex);

    useEffect(() => {
      setSelectedIndex(activeIndex);
    }, [activeIndex]);

    useEffect(() => {
      // reset back condition when popup open
      setSetCurrentActiveCondition(activeCondition);
    }, [isOpen]);

    // if category not define in the list fall back to Others
    const conditionDescription =
      conditionDescriptionMap[category] || conditionDescriptionMap.Others;

    const conditionImages = conditionImagesMap[category] || [];

    // determine slider preset using active index
    const matchedConditionImagesSelected =
      conditionImages.find(
        (conditionImageMap) =>
          conditionImageMap.name === NEW_CONDITIONS[activeIndex]?.title,
      )?.images || [];
    const sliderPreset = matchedConditionImagesSelected?.length > 0;

    const isPremiumSelected = NEW_CONDITIONS[activeIndex]?.handle === 'premium';

    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-30"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-30"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-white lg:bg-gray-700"
              onClick={props.onClose}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full xl:w-1/2">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-full">
                    <div className="relative flex h-full flex-col bg-white">
                      <div className="px-4 py-4 shadow sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-bold leading-6 text-gray-700 xs:text-lg"
                          >
                            Condition Detail
                          </Dialog.Title>
                          <button
                            onClick={props.onClose}
                            type="button"
                            className="btn-close opacity-100 md:h-3 md:w-3"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                      <div
                        className="relative flex-1 overflow-y-auto px-4 pt-6 sm:px-6"
                        // eslint-disable-next-line react/forbid-dom-props
                        style={
                          isPremiumSelected
                            ? {
                                background:
                                  'linear-gradient(92.65deg, #222122 38.4%, #13505B 97.23%)',
                              }
                            : undefined
                        }
                      >
                        {NEW_CONDITIONS.map((condition, index) => {
                          const matchedConditionImages =
                            conditionImages.find(
                              (conditionImageMap) =>
                                conditionImageMap.name === condition.title,
                            )?.images || [];

                          return (
                            <div
                              key={condition.handle}
                              // eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/migration-from-tailwind-2
                              className={cn(
                                'z-10 h-full bg-white ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                {
                                  '!bg-transparent': isPremiumSelected,
                                },
                                {
                                  hidden: selectedIndex !== index,
                                },
                              )}
                            >
                              <ul className="h-full">
                                <li
                                  key={condition.title}
                                  className="relative inline-block h-full w-full"
                                >
                                  <div className="flex h-full flex-col">
                                    {sliderPreset && (
                                      <div
                                        className={cn({
                                          isPremium: isPremiumSelected,
                                        })}
                                      >
                                        {matchedConditionImages.length > 1 ? (
                                          <>
                                            <Slider
                                              breakpoints={breakpoints}
                                              hideArrow
                                              paginationDots
                                              lazyLoad
                                            >
                                              {matchedConditionImages?.map(
                                                (image, i) => (
                                                  <div
                                                    key={`image_${i}_${condition.title}`}
                                                    className={cn(
                                                      'h-full rounded-lg bg-[#EFF2F9]',
                                                      {
                                                        '!bg-[#182022]':
                                                          isPremiumSelected,
                                                      },
                                                    )}
                                                  >
                                                    <Image
                                                      src={image}
                                                      alt={condition.title}
                                                      width={500}
                                                      height={450}
                                                      className="swiper-lazy h-full w-full object-fill"
                                                      loading="lazy"
                                                      quality={100}
                                                    />
                                                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                                  </div>
                                                ),
                                              )}
                                            </Slider>
                                          </>
                                        ) : (
                                          <div className="w-full pb-6">
                                            <div
                                              className={cn(
                                                'w-full rounded-lg bg-[#EFF2F9]',
                                                {
                                                  '!bg-[#182022]':
                                                    isPremiumSelected,
                                                },
                                              )}
                                            >
                                              <Image
                                                src={matchedConditionImages[0]}
                                                alt={condition.title}
                                                width={500}
                                                height={450}
                                                className="mx-auto h-full w-[85vw] object-contain xs:w-[77vw] md:w-[38vw] xl:w-[23vw] 2xl:w-[31vw]"
                                                quality={100}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <ConditionTab
                                      newCondition={NEW_CONDITIONS}
                                      sliderPreset={sliderPreset}
                                      isPremiumSelected={isPremiumSelected}
                                      selectedIndex={selectedIndex}
                                      isOpen={isOpen}
                                      setSelectedIndex={(selected) =>
                                        setSelectedIndex(selected)
                                      }
                                      setSetCurrentActiveCondition={(
                                        conditionStr,
                                      ) =>
                                        setSetCurrentActiveCondition(
                                          conditionStr,
                                        )
                                      }
                                    />
                                    <div
                                      className={cn(
                                        'flex w-full flex-col py-4 xs:py-6 lg:py-4',
                                        {
                                          'items-start justify-start':
                                            sliderPreset,
                                        },
                                        {
                                          'mt-[15vh] !h-full w-full items-center justify-start':
                                            !sliderPreset,
                                        },
                                      )}
                                    >
                                      <h3
                                        className={cn(
                                          'mb-2 font-extrabold leading-5 xs:mb-3',
                                          {
                                            'text-lg xs:mb-4 xs:text-xl lg:mb-2 lg:text-base':
                                              sliderPreset,
                                          },
                                          {
                                            'mb-2 text-lg xxs:mb-4 xs:text-2xl':
                                              !sliderPreset,
                                          },
                                          {
                                            'text-white': isPremiumSelected,
                                          },
                                        )}
                                      >
                                        Condition Description
                                      </h3>
                                      <div
                                        className={cn(
                                          ' text-gray-700 lg:text-xs',
                                          {
                                            'text-left text-xs xs:text-sm':
                                              sliderPreset,
                                          },
                                          {
                                            'text-center text-xs xxs:text-sm':
                                              !sliderPreset,
                                          },
                                          {
                                            '!text-white': isPremiumSelected,
                                          },
                                        )}
                                      >
                                        {/* Only empty or undefined will fallback to default description */}
                                        {(conditionDescription[
                                          condition.title
                                        ] == null ||
                                          conditionDescription[condition.title]
                                            ?.length === 0) &&
                                          condition.desc}
                                        {conditionDescription[
                                          condition.title
                                        ]?.map((c) => (
                                          <div
                                            className="[&:not(:first-child)]:mt-1"
                                            key={c.key}
                                          >
                                            <b className="pr-1">{c.key}:</b>
                                            <span>{c.description}</span>
                                          </div>
                                        ))}
                                        {condition.title === 'Premium' &&
                                          hasPremiumBattery && (
                                            <div className="mt-1">
                                              <b className="pr-1">Battery:</b>
                                              90% Minimum Battery Health
                                              Guaranteed
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    {isPremiumSelected && (
                                      <div className="-mr-4 mt-auto flex justify-end sm:-mr-6">
                                        <Image
                                          src={premiumBg}
                                          alt="Premium Condition"
                                          priority={true}
                                          className="h-full w-[17vh] object-contain"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  },
);

export { ConditionPopup };
