import { createRef, useEffect, useRef } from 'react';
import cn from 'classnames';
import Image from 'next/future/image';
import premiumBlack from '@/public/icons/product/premium_black.svg';
import premiumWhite from '@/public/icons/product/premium_white.svg';

export default function ConditionTab({
  newCondition,
  sliderPreset,
  isPremiumSelected,
  selectedIndex,
  isOpen,
  setSelectedIndex,
  setSetCurrentActiveCondition,
}: {
  newCondition: Array<{
    title: string;
    handle: string;
    desc: string;
  }>;
  sliderPreset: boolean;
  isPremiumSelected: boolean;
  selectedIndex: number;
  isOpen: boolean;
  setSelectedIndex: (index: number) => void;
  setSetCurrentActiveCondition: (condition: string) => void;
}) {
  const conditionRefs = useRef(new Array(newCondition.length));

  conditionRefs.current = newCondition.map(
    (_element, i) => conditionRefs.current[i] ?? createRef(),
  );

  useEffect(() => {
    // to auto scroll into view for the selected condition, since there is slider in mobile view
    if (
      isOpen &&
      selectedIndex &&
      conditionRefs.current[selectedIndex]?.current != null
    ) {
      conditionRefs.current[selectedIndex].current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [conditionRefs.current]);

  return (
    <div
      className={cn(
        'hide-scrollbar w-full shrink-0 py-5',
        {
          'flex grid-cols-5 flex-nowrap gap-x-0 overflow-x-auto xs:grid xs:gap-x-3 xs:overflow-x-hidden [&>*]:min-w-[80px] [&>*]:xxs:min-w-[100px] [&>*]:xs:min-w-full [&>*]:2xl:min-w-[80px] [&>*:not(:last-child)]:mr-2 [&>*:not(:last-child)]:xs:mr-0':
            newCondition.length === 5,
        },
        {
          '[&>*]:xs:min-min-w-[100px] grid grid-cols-4 gap-x-2 xs:gap-x-3 [&>*]:min-w-full':
            newCondition.length === 4,
        },
        {
          'flex flex-nowrap gap-x-0 overflow-x-auto sm:grid sm:grid-cols-6 sm:gap-x-2 [&>*]:min-w-[80px] [&>*]:xxs:min-w-[100px] [&>*]:sm:min-w-full [&>*:not(:last-child)]:mr-2 [&>*:not(:last-child)]:xs:mr-3':
            newCondition.length === 6,
        },
        {
          'mt-[10vh]': !sliderPreset,
        },
      )}
    >
      {newCondition.map((conditionTab, tabIndex) => {
        const selected = tabIndex === selectedIndex;

        return (
          <div
            key={`${conditionTab.handle}`}
            className={cn(
              'relative mb-0 flex min-h-full w-full min-w-full items-center justify-center rounded-md border py-5 text-xs leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 hover:cursor-pointer hover:border-gray-700 hover:font-bold focus:outline-none focus:ring-2',
              {
                'border-gray-500 text-gray-700': !isPremiumSelected,
              },
              {
                '!border-[#f2f2f280] text-white hover:!border-gray-500':
                  isPremiumSelected && !selected,
              },
              {
                'border bg-white font-bold': selected,
              },
              {
                'font-normal': !selected,
              },
              {
                '!border-gray-700 font-bold': !isPremiumSelected && selected,
              },
              {
                'border-gray-500 text-gray-700': isPremiumSelected && selected,
              },
            )}
            ref={conditionRefs.current[tabIndex]}
            onClick={() => {
              setSelectedIndex(tabIndex);
              setSetCurrentActiveCondition(newCondition[tabIndex].title);
            }}
          >
            <p>{conditionTab.title}</p>
            {conditionTab.handle === 'premium' && (
              <div className="absolute left-1/2 top-[-10px] inline-block h-[22px] w-[22px] -translate-x-1/2 rounded-full border border-gray-700 md:relative md:left-auto md:top-auto md:ml-2 md:h-[18px] md:w-[18px] md:translate-x-0 md:border-0">
                <Image
                  src={premiumBlack}
                  alt="Premium Condition"
                  width={12}
                  height={12}
                  className={cn('hidden h-full w-full object-contain md:block')}
                />
                <Image
                  src={premiumWhite}
                  alt="Premium Condition"
                  width={12}
                  height={12}
                  className={cn('block h-full w-full object-contain md:hidden')}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
