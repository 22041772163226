import usConditionDescription from './US.json';
import nonUsConditionDescription from './non-US.json';
import { CategoryConditionDescriptionMap } from './types';

let conditionDescription = {};

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-au')
  conditionDescription = nonUsConditionDescription;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-ca')
  conditionDescription = nonUsConditionDescription;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-us')
  conditionDescription = usConditionDescription;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-nz')
  conditionDescription = nonUsConditionDescription;

if (process.env.NEXT_PUBLIC_STORE === 'quista')
  conditionDescription = nonUsConditionDescription;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-dev')
  conditionDescription = nonUsConditionDescription;

export default conditionDescription as CategoryConditionDescriptionMap;
