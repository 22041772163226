import { CategoryConditionImageMap } from './types';
// Smartphones
import SmartphonesBrandNew1 from '@/public/images/conditionImages/Brand-new-Iphone-1.png';
import SmartphonesAcceptable1 from '@/public/images/conditionImages/Acceptable-iphone-1.png';
import SmartphonesAcceptable2 from '@/public/images/conditionImages/Acceptable-iphone-2.png';
import SmartphonesAcceptable3 from '@/public/images/conditionImages/Acceptable-iphone-3.png';
import SmartphonesAcceptable4 from '@/public/images/conditionImages/Acceptable-iphone-4.png';
import SmartphonesExcellent1 from '@/public/images/conditionImages/Excellent-iphone-1.png';
import SmartphonesExcellent2 from '@/public/images/conditionImages/Excellent-iphone-2.png';
import SmartphonesExcellent3 from '@/public/images/conditionImages/Excellent-iphone-3.png';
import SmartphonesExcellent4 from '@/public/images/conditionImages/Excellent-iphone-4.png';
import SmartphonesPremium1 from '@/public/images/conditionImages/Premium-iphone-1.png';
import SmartphonesPremium2 from '@/public/images/conditionImages/Premium-iphone-2.png';
import SmartphonesPremium3 from '@/public/images/conditionImages/Premium-iphone-3.png';
import SmartphonesPremium4 from '@/public/images/conditionImages/Premium-iphone-4.png';
import SmartphonesGood1 from '@/public/images/conditionImages/Good-iphone-1.png';
import SmartphonesGood2 from '@/public/images/conditionImages/Good-iphone-2.png';
import SmartphonesGood3 from '@/public/images/conditionImages/Good-iphone-3.png';
import SmartphonesGood4 from '@/public/images/conditionImages/Good-iphone-4.png';
// Smartwatches
import SmartwatchesBrandNew1 from '@/public/images/conditionImages/Brand-new-watch-1.png';
import SmartwatchesAcceptable1 from '@/public/images/conditionImages/Acceptable-watch-1.png';
import SmartwatchesAcceptable2 from '@/public/images/conditionImages/Acceptable-watch-2.png';
import SmartwatchesAcceptable3 from '@/public/images/conditionImages/Acceptable-watch-3.png';
import SmartwatchesAcceptable4 from '@/public/images/conditionImages/Acceptable-watch-4.png';
import SmartwatchesExcellent1 from '@/public/images/conditionImages/Excellent-watch-1.png';
import SmartwatchesExcellent2 from '@/public/images/conditionImages/Excellent-watch-2.png';
import SmartwatchesExcellent3 from '@/public/images/conditionImages/Excellent-watch-3.png';
import SmartwatchesExcellent4 from '@/public/images/conditionImages/Excellent-watch-4.png';
import SmartwatchesGood1 from '@/public/images/conditionImages/Good-watch-1.png';
import SmartwatchesGood2 from '@/public/images/conditionImages/Good-watch-2.png';
import SmartwatchesGood3 from '@/public/images/conditionImages/Good-watch-3.png';
import SmartwatchesGood4 from '@/public/images/conditionImages/Good-watch-4.png';
import SmartwatchesPremium1 from '@/public/images/conditionImages/Premium-watch-1.png';
import SmartwatchesPremium2 from '@/public/images/conditionImages/Premium-watch-2.png';
import SmartwatchesPremium3 from '@/public/images/conditionImages/Premium-watch-3.png';
import SmartwatchesPremium4 from '@/public/images/conditionImages/Premium-watch-4.png';
// Laptops
import LaptopsBrandNew1 from '@/public/images/conditionImages/Brand-new-macbook-1.png';
import LaptopsAcceptable1 from '@/public/images/conditionImages/Acceptable-macbook-1.png';
import LaptopsAcceptable2 from '@/public/images/conditionImages/Acceptable-macbook-2.png';
import LaptopsAcceptable3 from '@/public/images/conditionImages/Acceptable-macbook-3.png';
import LaptopsAcceptable4 from '@/public/images/conditionImages/Acceptable-macbook-4.png';
import LaptopsExcellent1 from '@/public/images/conditionImages/Excellent-macbook-1.png';
import LaptopsExcellent2 from '@/public/images/conditionImages/Excellent-macbook-2.png';
import LaptopsExcellent3 from '@/public/images/conditionImages/Excellent-macbook-3.png';
import LaptopsExcellent4 from '@/public/images/conditionImages/Excellent-macbook-4.png';
import LaptopsGood1 from '@/public/images/conditionImages/Good-macbook-1.png';
import LaptopsGood2 from '@/public/images/conditionImages/Good-macbook-2.png';
import LaptopsGood3 from '@/public/images/conditionImages/Good-macbook-3.png';
import LaptopsGood4 from '@/public/images/conditionImages/Good-macbook-4.png';
import LaptopsPremium1 from '@/public/images/conditionImages/Premium-macbook-1.png';
import LaptopsPremium2 from '@/public/images/conditionImages/Premium-macbook-2.png';
import LaptopsPremium3 from '@/public/images/conditionImages/Premium-macbook-3.png';
import LaptopsPremium4 from '@/public/images/conditionImages/Premium-macbook-4.png';
// Tablets
import TabletsBrandNew1 from '@/public/images/conditionImages/Brand-new-ipad-1.png';
import TabletsAcceptable1 from '@/public/images/conditionImages/Acceptable-ipad-1.png';
import TabletsAcceptable2 from '@/public/images/conditionImages/Acceptable-ipad-2.png';
import TabletsAcceptable3 from '@/public/images/conditionImages/Acceptable-ipad-3.png';
import TabletsAcceptable4 from '@/public/images/conditionImages/Acceptable-ipad-4.png';
import TabletsExcellent1 from '@/public/images/conditionImages/Excellent-ipad-1.png';
import TabletsExcellent2 from '@/public/images/conditionImages/Excellent-ipad-2.png';
import TabletsExcellent3 from '@/public/images/conditionImages/Excellent-ipad-3.png';
import TabletsExcellent4 from '@/public/images/conditionImages/Excellent-ipad-4.png';
import TabletsGood1 from '@/public/images/conditionImages/Good-ipad-1.png';
import TabletsGood2 from '@/public/images/conditionImages/Good-ipad-2.png';
import TabletsGood3 from '@/public/images/conditionImages/Good-ipad-3.png';
import TabletsGood4 from '@/public/images/conditionImages/Good-ipad-4.png';
import TabletsPremium1 from '@/public/images/conditionImages/Premium-ipad-1.png';
import TabletsPremium2 from '@/public/images/conditionImages/Premium-ipad-2.png';
import TabletsPremium3 from '@/public/images/conditionImages/Premium-ipad-3.png';
import TabletsPremium4 from '@/public/images/conditionImages/Premium-ipad-4.png';
// Gaming
import GamingBrandNew1 from '@/public/images/conditionImages/Brand-new-gaming-1.png';
import GamingAcceptable1 from '@/public/images/conditionImages/Acceptable-gaming-1.png';
import GamingAcceptable2 from '@/public/images/conditionImages/Acceptable-gaming-2.png';
import GamingAcceptable3 from '@/public/images/conditionImages/Acceptable-gaming-3.png';
import GamingAcceptable4 from '@/public/images/conditionImages/Acceptable-gaming-4.png';
import GamingExcellent1 from '@/public/images/conditionImages/Excellent-gaming-1.png';
import GamingExcellent2 from '@/public/images/conditionImages/Excellent-gaming-2.png';
import GamingExcellent3 from '@/public/images/conditionImages/Excellent-gaming-3.png';
import GamingExcellent4 from '@/public/images/conditionImages/Excellent-gaming-4.png';
import GamingGood1 from '@/public/images/conditionImages/Good-gaming-1.png';
import GamingGood2 from '@/public/images/conditionImages/Good-gaming-2.png';
import GamingGood3 from '@/public/images/conditionImages/Good-gaming-3.png';
import GamingGood4 from '@/public/images/conditionImages/Good-gaming-4.png';
import GamingPremium1 from '@/public/images/conditionImages/Premium-gaming-1.png';
import GamingPremium2 from '@/public/images/conditionImages/Premium-gaming-2.png';
import GamingPremium3 from '@/public/images/conditionImages/Premium-gaming-3.png';
import GamingPremium4 from '@/public/images/conditionImages/Premium-gaming-4.png';
// Airpods
import AirpodsBrandNew1 from '@/public/images/conditionImages/Brand-new-airpods-1.png';
import AirpodsAcceptable1 from '@/public/images/conditionImages/Acceptable-airpods-1.png';
import AirpodsAcceptable2 from '@/public/images/conditionImages/Acceptable-airpods-2.png';
import AirpodsAcceptable3 from '@/public/images/conditionImages/Acceptable-airpods-3.png';
import AirpodsAcceptable4 from '@/public/images/conditionImages/Acceptable-airpods-4.png';
import AirpodsExcellent1 from '@/public/images/conditionImages/Excellent-airpods-1.png';
import AirpodsExcellent2 from '@/public/images/conditionImages/Excellent-airpods-2.png';
import AirpodsExcellent3 from '@/public/images/conditionImages/Excellent-airpods-3.png';
import AirpodsExcellent4 from '@/public/images/conditionImages/Excellent-airpods-4.png';
import AirpodsGood1 from '@/public/images/conditionImages/Good-airpods-1.png';
import AirpodsGood2 from '@/public/images/conditionImages/Good-airpods-2.png';
import AirpodsGood3 from '@/public/images/conditionImages/Good-airpods-3.png';
import AirpodsGood4 from '@/public/images/conditionImages/Good-airpods-4.png';
import AirpodsPremium1 from '@/public/images/conditionImages/Premium-airpods-1.png';
import AirpodsPremium2 from '@/public/images/conditionImages/Premium-airpods-2.png';
import AirpodsPremium3 from '@/public/images/conditionImages/Premium-airpods-3.png';
import AirpodsPremium4 from '@/public/images/conditionImages/Premium-airpods-4.png';

const conditionImages = {
  Smartphones: [
    {
      name: 'Brand New',
      images: [SmartphonesBrandNew1],
    },
    {
      name: 'Premium',
      images: [
        SmartphonesPremium1,
        SmartphonesPremium2,
        SmartphonesPremium3,
        SmartphonesPremium4,
      ],
    },
    {
      name: 'Excellent',
      images: [
        SmartphonesExcellent1,
        SmartphonesExcellent2,
        SmartphonesExcellent3,
        SmartphonesExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [
        SmartphonesGood1,
        SmartphonesGood2,
        SmartphonesGood3,
        SmartphonesGood4,
      ],
    },
    {
      name: 'Acceptable',
      images: [
        SmartphonesAcceptable1,
        SmartphonesAcceptable2,
        SmartphonesAcceptable3,
        SmartphonesAcceptable4,
      ],
    },
  ],
  Laptops: [
    {
      name: 'Brand New',
      images: [LaptopsBrandNew1],
    },
    {
      name: 'Premium',
      images: [
        LaptopsPremium1,
        LaptopsPremium2,
        LaptopsPremium3,
        LaptopsPremium4,
      ],
    },
    {
      name: 'Excellent',
      images: [
        LaptopsExcellent1,
        LaptopsExcellent2,
        LaptopsExcellent3,
        LaptopsExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [LaptopsGood1, LaptopsGood2, LaptopsGood3, LaptopsGood4],
    },
    {
      name: 'Acceptable',
      images: [
        LaptopsAcceptable1,
        LaptopsAcceptable2,
        LaptopsAcceptable3,
        LaptopsAcceptable4,
      ],
    },
  ],
  Tablets: [
    {
      name: 'Brand New',
      images: [TabletsBrandNew1],
    },
    {
      name: 'Premium',
      images: [
        TabletsPremium1,
        TabletsPremium2,
        TabletsPremium3,
        TabletsPremium4,
      ],
    },
    {
      name: 'Excellent',
      images: [
        TabletsExcellent1,
        TabletsExcellent2,
        TabletsExcellent3,
        TabletsExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [TabletsGood1, TabletsGood2, TabletsGood3, TabletsGood4],
    },
    {
      name: 'Acceptable',
      images: [
        TabletsAcceptable1,
        TabletsAcceptable2,
        TabletsAcceptable3,
        TabletsAcceptable4,
      ],
    },
  ],
  Smartwatches: [
    {
      name: 'Brand New',
      images: [SmartwatchesBrandNew1],
    },
    {
      name: 'Premium',
      images: [
        SmartwatchesPremium1,
        SmartwatchesPremium2,
        SmartwatchesPremium3,
        SmartwatchesPremium4,
      ],
    },
    {
      name: 'Excellent',
      images: [
        SmartwatchesExcellent1,
        SmartwatchesExcellent2,
        SmartwatchesExcellent3,
        SmartwatchesExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [
        SmartwatchesGood1,
        SmartwatchesGood2,
        SmartwatchesGood3,
        SmartwatchesGood4,
      ],
    },
    {
      name: 'Acceptable',
      images: [
        SmartwatchesAcceptable1,
        SmartwatchesAcceptable2,
        SmartwatchesAcceptable3,
        SmartwatchesAcceptable4,
      ],
    },
  ],
  Gaming: [
    {
      name: 'Brand New',
      images: [GamingBrandNew1],
    },
    {
      name: 'Premium',
      images: [GamingPremium1, GamingPremium2, GamingPremium3, GamingPremium4],
    },
    {
      name: 'Excellent',
      images: [
        GamingExcellent1,
        GamingExcellent2,
        GamingExcellent3,
        GamingExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [GamingGood1, GamingGood2, GamingGood3, GamingGood4],
    },
    {
      name: 'Acceptable',
      images: [
        GamingAcceptable1,
        GamingAcceptable2,
        GamingAcceptable3,
        GamingAcceptable4,
      ],
    },
  ],
  'Gaming Consoles': [
    {
      name: 'Brand New',
      images: [GamingBrandNew1],
    },
    {
      name: 'Premium',
      images: [GamingPremium1, GamingPremium2, GamingPremium3, GamingPremium4],
    },
    {
      name: 'Excellent',
      images: [
        GamingExcellent1,
        GamingExcellent2,
        GamingExcellent3,
        GamingExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [GamingGood1, GamingGood2, GamingGood3, GamingGood4],
    },
    {
      name: 'Acceptable',
      images: [
        GamingAcceptable1,
        GamingAcceptable2,
        GamingAcceptable3,
        GamingAcceptable4,
      ],
    },
  ],
  'Gaming Consoles Bundle': [
    {
      name: 'Brand New',
      images: [GamingBrandNew1],
    },
    {
      name: 'Premium',
      images: [GamingPremium1, GamingPremium2, GamingPremium3, GamingPremium4],
    },
    {
      name: 'Excellent',
      images: [
        GamingExcellent1,
        GamingExcellent2,
        GamingExcellent3,
        GamingExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [GamingGood1, GamingGood2, GamingGood3, GamingGood4],
    },
    {
      name: 'Acceptable',
      images: [
        GamingAcceptable1,
        GamingAcceptable2,
        GamingAcceptable3,
        GamingAcceptable4,
      ],
    },
  ],
  Earphones: [
    {
      name: 'Brand New',
      images: [AirpodsBrandNew1],
    },
    {
      name: 'Premium',
      images: [
        AirpodsPremium1,
        AirpodsPremium2,
        AirpodsPremium3,
        AirpodsPremium4,
      ],
    },
    {
      name: 'Excellent',
      images: [
        AirpodsExcellent1,
        AirpodsExcellent2,
        AirpodsExcellent3,
        AirpodsExcellent4,
      ],
    },
    {
      name: 'Good',
      images: [AirpodsGood1, AirpodsGood2, AirpodsGood3, AirpodsGood4],
    },
    {
      name: 'Acceptable',
      images: [
        AirpodsAcceptable1,
        AirpodsAcceptable2,
        AirpodsAcceptable3,
        AirpodsAcceptable4,
      ],
    },
  ],
};

export default conditionImages as CategoryConditionImageMap;
